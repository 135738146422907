// src/components/HowToBuyModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';

const HowToBuyModal = ({ isOpen, onClose, playHoverSound, playClickSound }) => {
  if (!isOpen) return null;

  // Define in-depth steps with optional images
  const steps = [
    {
      title: "Set Up Your Wallet",
      content: (
        <ol className="list-none list-inside space-y-5">
            <li>
                <p>Download and install MetaMask, Rabby, or another wallet compatible with the Sanko chain.</p>
            </li>
            <li>
                <p>Create a new wallet and securely save your seed phrase.</p>
            </li>
        </ol>
      ),
      image: "/1.png", 
      imagePosition: "start", 
    },
    {
        title: "Buy DMT on Ethereum or Arbitrum",
        content: (
          <>
            <ol className="list-none list-inside space-y-1">
                <li>
                    <p>Purchase DMT on the Ethereum or Arbitrum networks using your wallet.</p>
                </li>
                <li>
                    <p>If you want to buy on Ethereum, use Uniswap</p>
                </li>
                <li>
                    <p>If you want to buy on Arbitrum (Recommended), use Camelot.</p>
                </li>
            </ol>
          </>
        ),
        image: "/2.png",       // Optional image path
        imagePosition: "start",
      },
    {
      title: "Add Sanko Chain",
      content: (
        <>
          <p>Open your wallet and select the network required to buy $SP.</p>
          <p>Use Chainlist or your wallet's custom network settings if needed.</p>
        </>
      ),
      image: "/3.png",
      imagePosition: "end",
    },
    {
        title: "Bridge DMT to Sanko Chain",
        content: (
          <>
            <p>
              Use the official Sanko Bridge at&nbsp;
              <a 
                className="underline text-lm-yellow" 
                href="https://sanko.xyz/bridge" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                https://sanko.xyz/bridge
              </a>
              &nbsp;to bridge your DMT to the Sanko chain.
            </p>
          </>
        ),
        image: "/4.png",    // Optional image path
        imagePosition: "end",
      },
    {
      title: "Visit Camelot Exchange",
      content: (
        <>
          <p>Navigate to the Camelot exchange website.</p>
          <p>Ensure your wallet is connected to the site.</p>
        </>
      ),
      image: "/5.png",
      imagePosition: "start",
    },
    {
        title: "Swap DMT to Buy $SP",
        content: (
          <>
            <p>Select DMT and swap it for $SP on Camelot.</p>
          </>
        ),
        image: "/6.png",   // Optional image path
        imagePosition: "end",
      },
    // Add more steps as needed
  ];

  const [currentStep, setCurrentStep] = useState(0);

  const goToNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose} 
      onMouseEnter={playHoverSound} 
    >
      <div 
        className="bg-lm-dark-gray p-6 rounded shadow-lg max-w-md w-full border-4 border-dashed border-lm-orange overflow-y-auto max-h-[80vh] relative"
        onClick={(e) => e.stopPropagation()}  
      >
        {/* 'X' Close Button */}
        <button
          onClick={() => { playClickSound(); onClose(); }}
          onMouseEnter={playHoverSound}
          className="absolute top-2 right-2 text-lm-orange hover:text-lm-yellow transition-colors"
          aria-label="Close Modal"
        >
          <FiX size={24} />
        </button>

        {/* Current Step Title */}
        <h2 className="text-xl font-bold mb-4 text-lm-orange text-center">
          {steps[currentStep].title}
        </h2>
        
        {/* Step Content with Optional Image */}
        <div className="space-y-4 text-lm-orange">
          {steps[currentStep].image && steps[currentStep].imagePosition === "start" && (
            <img 
              src={steps[currentStep].image} 
              alt={`${steps[currentStep].title} Illustration`} 
              className="w-1/2 h-auto rounded mx-auto -z-50" 
            />
          )}
          
          <div className="text-center">
            {steps[currentStep].content}
          </div>

          {steps[currentStep].image && steps[currentStep].imagePosition === "end" && (
            <img 
              src={steps[currentStep].image} 
              alt={`${steps[currentStep].title} Illustration`} 
              className="w-1/2 h-auto rounded mx-auto" 
            />
          )}
        </div>
        
        {/* Navigation Buttons */}
        <div className="mt-6 flex justify-between">
          <button 
            onClick={() => { playClickSound(); goToPrevious(); }} 
            onMouseEnter={playHoverSound}
            disabled={currentStep === 0}
            className={`px-4 py-2 font-semibold rounded transition-colors ${
              currentStep === 0 
                ? 'opacity-50 cursor-not-allowed' 
                : 'bg-lm-orange text-black hover:bg-lm-yellow'
            }`}
          >
            Back
          </button>
          <button 
            onClick={() => { playClickSound(); goToNext(); }} 
            onMouseEnter={playHoverSound}
            disabled={currentStep === steps.length - 1}
            className={`px-4 py-2 font-semibold rounded transition-colors ${
              currentStep === steps.length - 1 
                ? 'opacity-50 cursor-not-allowed' 
                : 'bg-lm-orange text-black hover:bg-lm-yellow'
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

HowToBuyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    playHoverSound: PropTypes.func.isRequired,
    playClickSound: PropTypes.func.isRequired,
};

export default HowToBuyModal;
