// src/App.js
import React, { useRef, useState, useEffect } from 'react';
import { FaTelegram, FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import Dropdown from './components/Dropdown';
import ProjectItem from './components/ProjectItem';
import SimpleItem from './components/SimpleItem';
import Typewriter from './components/Typewriter'; // Importing Typewriter
import ComingSoonItem from './components/ComingSoonItem';
import projects from './data/Projects';
import HowToBuyModal from './components/HowToBuyModal';
import NewItem from './components/NewItem';

const App = () => {
  const hoverSound = useRef(null);
  const clickSound = useRef(null);
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const logoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Simulate loading time (e.g., 5 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 3000ms = 3 seconds

    return () => clearTimeout(timer);
  }, []);

  const allowAudio = () => {
    setAudioAllowed(true);
  };

  useEffect(() => {
    // Listen for the first user interaction (click) to enable audio
    document.addEventListener('click', allowAudio, { once: true });
    return () => {
      document.removeEventListener('click', allowAudio);
    };
  }, []);

  const playHoverSound = () => {
    if (audioAllowed && hoverSound.current) {
      hoverSound.current.currentTime = 0;
      hoverSound.current.play();
    }
  };

  const playClickSound = () => {
    if (audioAllowed && clickSound.current) {
      clickSound.current.currentTime = 0;
      clickSound.current.play();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
      playClickSound();
    }).catch(() => {
      toast.error("Failed to copy!");
    });
  };

  // Trigger the ring animation on page load
  useEffect(() => {
    const logo = logoRef.current;
    if (logo) {
      // Add the animation class to start the animation
      logo.classList.add('ring-animation');

      // Remove the animation class after the animation completes
      const handleAnimationEnd = () => {
        logo.classList.remove('ring-animation');
      };

      logo.addEventListener('animationend', handleAnimationEnd);

      // Cleanup the event listener on unmount
      return () => {
        logo.removeEventListener('animationend', handleAnimationEnd);
      };
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen relative z-10 bg-lm-dark-gray">
        <>
          {/* Scanlines - Fixed and Independent of Content */}
          <div className="scanlines"></div>
  
          {/* Audio Elements */}
          <audio ref={hoverSound} src="/buttonhover.mp3" />
          <audio ref={clickSound} src="/buttonclick.mp3" />
  
          {/* Header */}
          <header className="w-full bg-lm-dark-gray py-4 flex justify-center items-center">
            <img
              src="/logo.png"
              alt="SmooviePhone Logo"
              ref={logoRef} // Attach the ref
              className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full mr-4 object-contain transform rotate-6 transition-transform duration-300 hover:rotate-12 hover:scale-105 cursor-pointer"
              tabIndex={0}
              onClick={() => window.location.href = '/'}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  window.location.href = '/';
                }
              }}
            />
            <h1 className="text-lm-yellow text-3xl sm:text-4xl md:text-5xl">SmooviePhone</h1>
          </header>
  
          {/* Tagline */}
          <div className="flex flex-col items-center my-4">
            <hr className="w-64 border-lm-orange mb-2" />
            <p className="text-lm-red text-lg sm:text-xl text-center px-4">
              {/*<Typewriter text="Where DeFi meets GameFi" speed={60} cursor="|" />*/}
              <Typewriter text="Decentralized Parlay Platform (D.P.P) is now LIVE 🚀!" speed={60} cursor="|" />
            </p>
            <hr className="w-64 border-lm-orange mt-2" />
          </div>
  
          {/* Main Content */}
          <main className="flex-grow flex flex-col items-center justify-center text-lm-yellow px-4 py-8">
            <div className="w-full max-w-6xl lm-dark-gray p-6 shadow-lg border-4 border-dashed border-lm-orange">
              <ul className="space-y-4">
                {projects.map((project) => (
                  <li key={project.name}>
                    {project.new ? (
                      <NewItem
                        name={project.name}
                        link={project.link}
                        info={project.info}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                      />
                    ) :project.comingSoon ? (
                      <ComingSoonItem
                        name={project.name}
                        info={project.info}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                      />
                    ) : project.dropdown ? (
                      <Dropdown
                        title={project.name}
                        items={project.items}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                        copyToClipboard={copyToClipboard}
                        isOpen={openDropdown === project.name}
                        setOpenDropdown={setOpenDropdown}
                      />
                    ) : project.items ? (
                      <ProjectItem
                        name={project.name}
                        items={project.items}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                        copyToClipboard={copyToClipboard}
                      />
                    ) : (
                      <SimpleItem
                        name={project.name}
                        link={project.link}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </main>

          {/* Fixed "How to Buy?" Button */}
          <button
            className="fixed bottom-4 right-4 bg-lm-orange bg-opacity-70 text-black px-4 py-2 rounded hover:bg-opacity-100 transition-colors"
            onClick={() => { playClickSound(); setIsModalOpen(true) }} 
            onMouseEnter={playHoverSound}
          >
            How to Buy?
          </button>
          {/* HowToBuyModal */}
          <HowToBuyModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            playHoverSound={playHoverSound}
            playClickSound={playClickSound}
          />
  
          {/* Footer */}
          <footer className="w-full bg-lm-black py-4 flex flex-col items-center">
            <div className="flex space-x-4 mb-2">
              <a href="https://t.me/smooviephonehotline" target="_blank" rel="noopener noreferrer" onMouseEnter={playHoverSound} onClick={playClickSound}>
                <FaTelegram size={24} className="text-lm-yellow hover:text-lm-orange transition-colors cursor-pointer" />
              </a>
              <a href="https://x.com/SmooviePhone" target="_blank" rel="noopener noreferrer" onMouseEnter={playHoverSound} onClick={playClickSound}>
                <FaXTwitter size={24} className="text-lm-yellow hover:text-lm-orange transition-colors cursor-pointer" />
              </a>
              <a href="https://discord.gg/TFzrRQ5zch" target="_blank" rel="noopener noreferrer" onMouseEnter={playHoverSound} onClick={playClickSound}>
                <FaDiscord size={24} className="text-lm-yellow hover:text-lm-orange transition-colors cursor-pointer" />
              </a>
            </div>
            <p className="text-lm-terminal-lightgray text-sm">© 2024 SmooviePhone. All rights reserved.</p>
          </footer>
  
          {/* Toast Notifications */}
          <ToastContainer 
            position="top-right" 
            autoClose={3000} 
            hideProgressBar={false} 
            newestOnTop={false} 
            closeOnClick 
            rtl={false} 
            pauseOnFocusLoss 
            draggable 
            pauseOnHover 
            theme="dark" // You can choose 'light', 'dark', or 'colored'
            className="custom-toast-container"
          />
        </>
    </div>
  );
};

export default App;
